export const selectSideNavbar = (e) => {
    console.log(e.target);
    e.preventDefault();
    const navItems = document.querySelectorAll('.custom-sidebar-item');
    navItems.forEach(item => {
        if(item.classList.contains('active')) {
            item.classList.remove('active');
        }
    })
    e.target.classList.add('active');
}