<template>
  <div class="custom-navbar w-100 bg-white">
    <div>
      <img src="@/assets/Pg-logo2.png" style="height:3rem; width:auto;"/>
    </div>
    <div class="d-flex justify-content-between align-items-center w-50">
      <div class="position-relative">
        <div 
          class="position-absolute"
          style="top:50%;transform:translateY(-50%);left:5px;"
        >
          <!-- <i class="uil-search"></i> -->
        </div>
        <!-- <input
          type="text"
          class="form-control custom-searchbar"
          placeholder="Search"
        /> -->
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <b-dropdown
          class="d-inline-block"
          toggle-class="header-item"
          right
          variant="white"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar.svg"
              alt="Header Avatar"
            />
            <span
              class="d-none d-xl-inline-block ms-1 fw-medium font-size-15"
              >Account</span
            >
            <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
          </template>

          <!-- item-->
          <a class="dropdown-item" @click="profile" style="cursor: pointer;">
            <i
              class="uil uil-user-circle font-size-18 align-middle text-muted me-1"
            ></i>
            <span class="align-middle">Profile</span>
          </a>

          <a
            class="dropdown-item"
            href="javascript: void(0);"
            @click="logout"
          >
            <i
              class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"
            ></i>
            <span class="align-middle">Logout</span>
          </a>
        </b-dropdown>
        <div class="special-menu" @click="toggleDisplay">
          <div style="height:3px; width:3rem; border-radius:3px; background-color:#111; margin-bottom:5px;"></div>
          <div style="height:3px; width:3rem; border-radius:3px; background-color:#111; margin-bottom:5px;"></div>
          <div style="height:3px; width:3rem; border-radius:3px; background-color:#111; margin-bottom:5px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //
    };
  },
  methods: {
      logout() {
        localStorage.removeItem("app_access_token");
        localStorage.removeItem("app_refresh_token");
        this.$router.push("/");
      },
      profile() {
      this.$router.push("/pages/profile");
    },
    toggleDisplay(){
          document.getElementById('big-menu').classList.toggle('d-none');
      }
  },
  components: {
  }
};
</script>
<style lang="scss" scoped>
.custom-navbar {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.custom-searchbar {
  width: 20rem;
  height: 34px;
  border-radius: 5px;
  border: none;
  padding: 0 10px;
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.05);
  transition: 0.3s ease;
  padding-left: 1.5rem;
  &:active,
  &:focus {
    box-shadow: 0px 5px 10px -5px rgba(#4361ee, 0.35);
  }
}

  .special-menu {
    display: none;
  }

  @media only screen and (max-width: 991px) {
    .special-menu {
      display: block !important;
    }
  }

</style>
