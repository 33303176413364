<template>
  <div class="w-100 vh-100">
    <div>
      <Navbar />
    </div>
    <div>
      <BigMenu />
    </div>
    <div
      class="d-flex justify-content-center align-items-start w-100 position-relative"
    >
      <div class="custom-width-small">
        <Sidebar :changeNavstate="changeNavstate" />
      </div>
      <div class="custom-width-large px-4 py-2 position-relative">
        <slot />
        <!-- <div v-if="navstate === 1">
          <HomeScreenTwo />
        </div>
        <div v-if="navstate === 2">
          Screen 3
        </div>-->
      </div> 
    </div>
  </div>
</template>
<script>
import Navbar from "@/new-components/Navbar/Navbar.vue";
import Sidebar from "@/new-components/sidebar/Sidebar.vue";
import BigMenu from '@/new-components/BigMenu/BigMenu.vue';
// import HomeScreenOne from "@/new-views/home-items/HomeScreenOne.vue";
// import HomeScreenTwo from "@/new-views/home-items/HomeScreenTwo.vue";
export default {
  data() {
    return {
      navstate: 0,
    };
  },
  methods: {
    changeNavstate(number) {
      this.navstate = number;
    },
  },
  components: {
    Navbar,
    Sidebar,
    BigMenu,
    // HomeScreenOne,
    // HomeScreenTwo,
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.custom-width-small {
  width: 20%;
  position: relative;
}
.custom-width-large {
  width: 80%;
  position: relative;
  overflow: auto;
  height: calc(100vh - 70px);
}

  @media only screen and (max-width: 991px) {
    .custom-width-small  {
      display: none !important;
    }
    .custom-width-large{
      width: 100% !important;
    }
  }
</style>
