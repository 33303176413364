<script>
import Home from "@/new-views/Home/Home.vue";
import appConfig from "@/app.config";

export default {
  components: {
    Home,
  },
  page: {
    title: "Profile",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Profile",
      disabled: true,
      user: {
        name: "Snehasish",
        email: "e.mail.com",
        password: "",
        phone:"+123456789",
      },
      editField: "",
    };
  },
  methods: {
    focusField(name) {
      this.editField = name;
    },
    blurField() {
      this.editField = "";
    },
    showField(name) {
      return this.user[name] == "" || this.editField == name;
    },
  },
};
</script>

<template>
  <!-- <PageHeader :title="title" :items="items" /> -->
  <Home>
    <div class="row mb-10">
      <div>
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center">
              <!-- <div>
                            <img src="@/assets/images/users/avatar-4.jpg" alt class="avatar-lg rounded-circle img-thumbnail" />
                        </div> -->
            </div>

            <hr class="my-4" />

            <div class="text-muted">
              <h2 class="mt-3 mb-1">Personal Details</h2>
              <div class="row w-full">
                <div class="col-4">
                  <label class="mb-0"><h5>Name</h5></label>
                  <br />
                  <span
                    class="field-value"
                    v-show="!showField('name')"
                    @click="focusField('name')"
                    >{{ user.name }}</span
                  >
                  <input
                    placeholder="Naam batao"
                    v-model="user.name"
                    v-show="showField('name')"
                    id="user-name"
                    type="text"
                    class="field-value form-control"
                    @focus="focusField('name')"
                    @blur="blurField"
                  />
                </div>
                <div class="col-4">
                  <label class="mb-0"><h5>Email</h5></label>
                  <br />
                  <span class="field-value">{{ user.email }}</span>
                </div>
                <div class="col-4">
                  <label class="mb-0"><h5>Phone No.</h5></label>
                  <br />
                  <span
                    class="field-value"
                    v-show="!showField('phone')"
                    @click="focusField('phone')"
                    >{{ user.phone }}</span
                  >
                  <input
                    placeholder="+123456789"
                    v-model="user.phone"
                    v-show="showField('phone')"
                    type="text"
                    class="field-value form-control"
                    @focus="focusField('phone')"
                    @blur="blurField"
                  />
                </div>
              </div>
              <div class="row-xl-5">
                <h2 class="mt-3 mb-1">Security Details</h2>
                <div class="row w-full">
                  <div class="col-4">
                    <label class="mb-0"><h5>Password</h5></label>
                    <br />
                    <span
                      class="field-value"
                      v-show="!showField('password')"
                      @click="focusField('password')"
                      >{{ user.password }}</span
                    >
                    <input
                      placeholder="**********"
                      v-model="user.password"
                      v-show="showField('password')"
                      type="password"
                      class="field-value form-control"
                      @focus="focusField('password')"
                      @blur="blurField"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Home>
</template>

