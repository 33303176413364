<template>
    <div class="custom-container d-none" id="big-menu">
        <div class="w-100 vh-100 d-flex flex-column align-items-center justify-content-evenly">
            <div style="position:absolute; top:10px; right:20px; font-size:2.3rem;" @click="toggleDisplay">
                &#10005;
            </div>
            <a href="/pages/dashboard">
                Dashboard
            </a>
            <a href="/pages/listings">
                Listings
            </a>
            <a href="/pages/tenants">
                Tenants
            </a>
            <a href="/pages/financials">
                Financials
            </a>
            <a href="/pages/units">
                Units
            </a>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        toggleDisplay(){
            document.getElementById('big-menu').classList.toggle('d-none');
        }
    }
}
</script>

<style lang="scss" scoped>
    .custom-container{
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        z-index: 100999;
    }
    a{
        font-size: 2rem;
        text-align: center;
        color: #111;
    }
</style>