<template>
  <div class="custom-sidebar position-relative px-4 py-2 bg-white">
    <div
      class="custom-sidebar-item d-flex align-items-center justify-content-between"
      :class="location.includes('dashboard') ? 'active' : ''"
      @click="toggleSidebar($event, 'Dashboard')"
      
    >
      <div class="d-flex align-items-end justify-content-center" style="pointer-events:none;">
        <i class="sidebar-icon uil-home-alt"></i> <span>Dashboard</span>
      </div>
    </div>
    <div
      class="custom-sidebar-item d-flex align-items-center justify-content-between"
      :class="location.includes('listings') ? 'active' : ''"
      @click="toggleSidebar($event, 'Listings')"
    >
      <div class="d-flex align-items-start justify-content-center" style="pointer-events:none;">
        <i class="sidebar-icon fas fa-columns"></i> <span>Buildings</span>
      </div>
    </div>
    <div
      class="custom-sidebar-item d-flex align-items-center justify-content-between"
      :class="location.includes('tenants') ? 'active' : ''"
      @click="toggleSidebar($event, 'Tenants')"
    >
      <div class="d-flex align-items-end justify-content-center" style="pointer-events:none;">
        <i class="sidebar-icon fas fa-user-circle"></i> <span>Tenants</span>
      </div>
    </div>
    <div
      class="custom-sidebar-item d-flex align-items-center justify-content-between"
      :class="location.includes('units') ? 'active' : ''"
      @click="toggleSidebar($event, 'Units')"
    >
      <div class="d-flex align-items-end justify-content-center" style="pointer-events:none;">
        <i class="sidebar-icon fas fa-house-user"></i> <span>Units</span>
      </div>
    </div>
    <div
      class="custom-sidebar-item d-flex align-items-center justify-content-between"
      :class="location.includes('financials') ? 'active' : ''"
      @click="toggleSidebar($event, 'Financials')"
    >
      <div class="d-flex align-items-end justify-content-center" style="pointer-events:none;">
        <i class="sidebar-icon fas fa-file-invoice-dollar"></i> <span>Financials</span>
      </div>
    </div>
  </div>
</template>
<script>
import {selectSideNavbar} from '@/utils/animations.js';
export default {
    name: 'Sidebar',
    data() {
        return {
        active: false,
        location: '',
        };
    },
    methods: {
      getLocation(){
        this.location = window.location.pathname;
      },
      toggleSidebar(e, sidebar) {
          selectSideNavbar(e);  
          this.$router.push({name: sidebar});
          console.log(location.pathname);
      },
    },
    mounted() {
      this.getLocation();
    },
    
};
</script>
<style lang="scss" scoped>
.custom-sidebar {
  height: calc(100vh - 70px);
  width: 100%;
}
.custom-sidebar-item {
  cursor: pointer;
  height: 2.75rem;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  padding: 0 .5rem;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #7b8190;
    pointer-events: none;
  }
  .sidebar-icon {
    color: #7b8190;
    font-size: 1.1rem;
    margin-right: 0.5rem;
  }
  &.active {
      background: #F3F8FB;
    span {
      font-size: 14px;
      font-weight: 500;
      color: #4361EE;
    }
    .sidebar-icon {
      color: #4361EE;
      font-size: 1.1rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
